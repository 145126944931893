import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '@services/storage.service';
import { AuthService } from '@services/auth.service';
import { RoleTypes } from '@models/role-types.enum';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	router = inject(Router);

	constructor(private authService: AuthService) {}

	private checkLogin(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// check if role is correct
		switch (state.url.split('/')[1] ?? '') {
			case 'patient':
				if (!this.authService.isLoggedIn || this.authService.role != RoleTypes.PATIENT)
					return this.router.parseUrl('/');
				break;
			case 'therapist':
				if (!this.authService.isLoggedIn || this.authService.role != RoleTypes.THERAPIST)
					return this.router.parseUrl('/');
				break;
			default:
				if (this.authService.isLoggedIn && this.authService.role == RoleTypes.PATIENT)
					return this.router.parseUrl('/patient');
				else if (this.authService.isLoggedIn && this.authService.role == RoleTypes.THERAPIST)
					return this.router.parseUrl('/therapist');
		}

		return true;
	}

	canActivate = this.checkLogin;
	canActivateChild = this.checkLogin;
}
